import React from "react";

const ProfileIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            fill="currentColor"
            viewBox="0 0 33 32"
            {...props}
        >
            <path
                fill="currentColor"
                d="M29.366 26.503c-1.904-3.29-4.838-5.65-8.261-6.77a9 9 0 10-9.208 0c-3.424 1.118-6.357 3.478-8.261 6.77a1 1 0 101.731 1c2.355-4.07 6.518-6.5 11.134-6.5 4.616 0 8.779 2.43 11.134 6.5a.999.999 0 101.73-1zM9.5 12.003a7 7 0 117 7 7.007 7.007 0 01-7-7z"
            ></path>
        </svg>
    );
}

export default ProfileIcon;
