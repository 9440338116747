import React from "react";

const Phone = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 -0.5 9 9"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" transform="translate(-345 -206)">
        <g transform="translate(56 160)">
          <path d="M298 49L298 51 294.625 51 294.625 54 292.375 54 292.375 51 289 51 289 49 292.375 49 292.375 46 294.625 46 294.625 49z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Phone;
