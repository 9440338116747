import React from "react";

const Location = (props) => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.692 7.943l-6.25 6.25a.625.625 0 01-.884 0l-6.25-6.25a.625.625 0 01.884-.885L10 12.867l5.808-5.809a.625.625 0 11.884.885z"
    ></path>
  </svg>
);


export default Location;
