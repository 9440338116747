import React from "react";
const contact = (props) =>  (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    fill="none"
    viewBox="0 0 33 32"
    {...props}
  >
    <path
     fill="currentColor"
      d="M19.534 4.741a.998.998 0 011.225-.707 10.777 10.777 0 017.707 7.707 1 1 0 11-1.932.516 8.794 8.794 0 00-6.291-6.291 1 1 0 01-.71-1.225zm-.291 5.225c1.723.46 2.83 1.568 3.29 3.291a1 1 0 101.933-.516c-.64-2.395-2.312-4.067-4.707-4.707a1 1 0 00-.517 1.932zm10.242 11.919A7.033 7.033 0 0122.5 28c-9.925 0-18-8.075-18-18a7.032 7.032 0 016.115-6.985 2 2 0 012.078 1.19l2.64 5.893v.015a2 2 0 01-.16 1.887 1.37 1.37 0 01-.07.096L12.5 15.181c.936 1.903 2.926 3.875 4.854 4.814l3.042-2.589a1 1 0 01.094-.07 2 2 0 011.896-.175l.017.008 5.888 2.638a2 2 0 011.194 2.078zm-1.985-.25h-.014l-5.875-2.632-3.044 2.59a1.03 1.03 0 01-.092.07 2 2 0 01-1.969.142c-2.341-1.131-4.675-3.448-5.807-5.764a2 2 0 01.125-1.963.77.77 0 01.071-.096l2.605-3.088-2.625-5.875v-.016A5.025 5.025 0 006.5 10a16.017 16.017 0 0016 16 5.026 5.026 0 005-4.366z"
    ></path>
  </svg>
  );


export default contact;
