import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./styles.scss";
import crossIcon from "../../images/cross-icon.svg";
import { useDispatch } from "react-redux";
import { ondcCancel } from "../../slices/ondcPostorderSlice";
import Loader from "../../components/common/loader";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";

const CancelOrder = (props) => {
  const orderId = props?.orderId
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [loading, setLoading] = useState(false);

  const cancel = async () => {
    try {
      if (!cancellationReason || cancellationReason==="") {
        setError("Please select a cancellation reason.");
        return;
      }
      setLoading(true);
      dispatch(
        ondcCancel({
          orderId: orderId,
          cancellationReason: cancellationReason,
        })
      ).then((data) => {
        if (data?.payload?.success) {
          props.setCancelModal(false);
          props.getOrderDetail()
          notify("success", "Order Cancelled");
        } else {
          notify("error", "Somthing Went Wrong Please Try Again After Some Time !");
          setError("Somthing Went Wrong Please Try Again After Some Time !");
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error cancelling order", error);
    }
  };

  const reasons = [
    { value: "", label: "Select reason for cancelation" },
    { value: "001", label: "Price of one or more items have changed" },
    { value: "003", label: "Product available at lower than order price" },
    { value: "006", label: "Order not received as per buyer app TAT SLA" },
  ];
  return (
    <>
    <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="right"
        position="top-right"
      />
    <Modal
      isOpen={props.cancelModal}
      size="md"
      centered
      toggle={() => props.setCancelModal(false)}
      className="RaiseTicketModal"
    >
      <ModalHeader>
        Cancel Order
        <button
          className="btn p-0"
          onClick={() => {
            props.setCancelModal(false);
          }}
        >
          <img alt="Close" src={crossIcon} />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="RaiseTicketCards">
          <p>
            <input type="radio" id="test1" name="radio-group" checked />
            <label for="test1">Cancel complete order</label>
          </p>
          <div className="reurn-order-modal">
            <div className="did-floating-label-content">
              <select
                className="did-floating-select"
                onclick="this.setAttribute('value', this.value);"
                onchange="this.setAttribute('value', this.value);"
                value={cancellationReason}
                onChange={(e) => {
                  setCancellationReason(e.target.value);
                  setError("");
                }}
              >
                {reasons.map((reason) => (
                  <option key={reason.value} value={reason.value}>
                    {reason.label}
                  </option>
                ))}
              </select>
              <label className="did-floating-label">Select your reason</label>
              {error.length > 0 && <p className="error">{error}</p>}
            </div>
          </div>
        </div>
        <div className="cancle-order-btn">
          <button
            className="btn btn-outline btn-md"
            onClick={() => {
              props.setCancelModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-md"
            disabled={loading}
            onClick={cancel}
          >
            {" "}
            {loading ? <Loader /> : "Confirm"}
          </button>
        </div>
      </ModalBody>
    </Modal>
    </>
  );
};
export default CancelOrder;
