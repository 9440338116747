import * as React from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import "./App.scss";

import ProductDetails from "../views/ProductDetails/component";
import Login from "../views/Login/component";
import ManageAddress from "../views/ManageAddress/component";
import PreRazorpay from "../views/Razorpay/PreRazorpay";
import SellerPage from "../views/SellerPage/component";
import Products from "../views/Products/component";
import HomePage from "../views/HomePage/component";
import Checkout from "../views/Checkout/component";
import Loginpage from "../views/Loginpage/component";
import OrderDetails from "../views/OrderDetails/component";
import Search from "../views/Search/component";
import ScrollToTop from "../components/common/ScrollToTop.js";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("token"));
  return isAuthenticated ? <Element /> : <Navigate to="/" replace />;
};


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/seller" element={<SellerPage />} />
        <Route path="/productDetails" element={<ProductDetails />} />
        <Route path="/manageAddress" element={<ManageAddress />} />
        <Route path="/login" element={<Login />} />
        <Route path="/preRazorpay" element={<PreRazorpay />} />
        <Route path="/product" element={<Products />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/buyer-checkout" element={<PrivateRoute element={Checkout} />} />
        <Route path="/buyer-login" element={<Loginpage />} />
        <Route path="/buyer-order-details" element={<PrivateRoute element={OrderDetails} />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </Router>
  );
}

export default App;
