
import { createSlice ,createAsyncThunk } from '@reduxjs/toolkit';
import {ondcSelectData,ondcCartData,ondcInitData,ondConfirmData}  from "../api/ApiService";
const ondcCartDataLocal = JSON.parse(localStorage.getItem("ondcCart"));
export const initialState = {
  ondcSelectstatus:"",
  ondcCartstatus:"",
  ondcConfirmstatus:"",
  ondcInitstatus:"",
  ondcCartError:"",
  ondcIsCartError: ondcCartDataLocal?.error?.message ? true : false,
  hasErrors:"",
  selectData: [],
  data: [],
  onInitData:[],
};

export const ondcPreorderSlice = createSlice({
  name: 'ondc',
  initialState,
  reducers: {
    clearOndcSelectData: state => {
      state.selectData = [];
      // state.data=[];
      state.ondcSelectstatus="";
      state.ondcCartstatus="";   
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ondcSelect.pending, (state) => {
        state.hasErrors = false;
        state.ondcSelectstatus = "loading"
      })
      .addCase(ondcSelect.fulfilled, (state, action) => {
        state.selectData = action.payload;
        state.ondcSelectstatus = "loaded"
        state.hasErrors = false;
         if(action?.payload?.cart?.raw?.error){
          state.ondcIsCartError = true
         }
         else{
          state.ondcIsCartError = false
         }
         
      })
      .addCase(ondcSelect.rejected, (state) => {
        state.ondcSelectstatus = "error"
        state.hasErrors = true;

      })
      .addCase(ondcCart.pending, (state) => {
        state.ondcCartstatus = "loading"
        state.hasErrors = false;
      })
      .addCase(ondcCart.fulfilled, (state, action) => {
        state.ondcCartstatus = "loaded"
        state.data = action.payload;
        state.hasErrors = false;
      })
      .addCase(ondcCart.rejected, (state) => {
        state.hasErrors = true;
      

      })
      .addCase(ondcInit.pending, (state) => {
        state.hasErrors = false;
        state.ondcInitstatus  = "loading"
    
      })
      .addCase(ondcInit.fulfilled, (state, action) => {
         state.onInitData = action.payload;
         state.ondcInitstatus  = "loaded"
      })
      .addCase(ondcInit.rejected, (state) => {
        state.ondcInitstatus  = "error"
      })
      .addCase(ondcConfirm.pending, (state) => {
        state.ondcConfirmstatus ="loading"
      })
      .addCase(ondcConfirm.fulfilled, (state, action) => {
         state.ondcConfirmstatus = "loaded"
      })
      .addCase(ondcConfirm.rejected, (state) => {
        state.ondcConfirmstatus = "error"
      })
      
  },
});


export const ondcSelect = createAsyncThunk(
  'ondc/select',
  async (body) => {
    const data =  ondcSelectData(body); 
    return data
     
  }
);

export const ondcCart = createAsyncThunk(
  'ondc/cart',
  async (body) => {
    const data =  ondcCartData(body); 
    return data
     
  }
);

export const ondcInit = createAsyncThunk(
  'ondc/init',
  async (body) => {
    const data =  ondcInitData(body); 
    return data
     
  }
);

export const ondcConfirm = createAsyncThunk(
  'ondc/confirm',
  async (body) => {
    const data =  ondConfirmData(body); 
    return data
     
  }
);




export const { clearOndcSelectData } = ondcPreorderSlice.actions;

export default ondcPreorderSlice.reducer;

