import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import Clothes from "../../images/ProductImg/clothes.png";
import Electronics from "../../images/ProductImg/electronics.png";
import Food from "../../images/ProductImg/food.png";
import Furniture from "../../images/ProductImg/furniture.png";
import Grocery from "../../images/ProductImg/grocery.png";
import Health from "../../images/ProductImg/health.png";
// import Singhla from "../../images/ProductImg/singhla.png";
import Personalcare from "../../images/ProductImg/personal-care.png";
import Sport from "../../images/ProductImg/sport.webp";
import BrandLogo from "../../images/kikoimg.svg";
import MartMall from "../../images/ProductImg/mart-mall.webp";
import { GET_CATEGORIES } from "../../api/apiList";
import API2 from "../../api/indexv2";
import "./styles.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchSellers } from "../../slices/sellerSlice";
import { get } from "lodash";
import Loader from "../../components/common/loader";
import NoData from "../../components/NewImages/no-data.png"
import Footer from "../../components/common/Footer";

const HomePage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [categoriesData, setCategoriesData] = useState(null); 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const categoryParam = searchParams.get("category") || "Grocery";
  // const [imageLoaded, setImageLoaded] = useState([]);
  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const navigate = useNavigate();


  const sellers = useSelector((s) => s.sellers.sellers);
  const sellersStatus = useSelector((s) => s.sellers.sellersStatus);

  

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    if (selectedCategory !== null) {
      getSellers();
    }
  }, [selectedCategory]);

  const getCategoriesData = async () => {
    try {
      const response = await API2.post(GET_CATEGORIES);
      if (response?.data) {
        let categories = response.data.data;

        // Ensure "Grocery" is the first category
        const groceryIndex = categories.findIndex((category) => category?.title === "Grocery");
        if (groceryIndex > -1) {
          const [groceryCategory] = categories.splice(groceryIndex, 1);
          categories.unshift(groceryCategory);
        }

        setCategoriesData(categories);

        const matchedCategory = categories.find(
          (category) => category?.title === categoryParam
        );
        setSelectedCategory(matchedCategory || categories[0]);
      }
    } catch (error) {
      console.log("ERROR CATEGORIES ", error);
    }
  };

  const getSellers = async () => {
    try {
      dispatch(
        fetchSellers({
          areaCode: defaultAddress?.zipcode,
          category: selectedCategory?.title,
        })
      );
    } catch (error) {
      console.log("ERROR FETCH SELLER ", error);
    }
  };

  const getImportedImage = (title) => {
    switch (title) {
      case "Beauty & Personal Care":
        return Personalcare;
      case "Electronics":
        return Electronics;
      case "Fashion":
        return Clothes;
      case "Food & Beverage":
        return Food;
      case "Grocery":
        return Grocery;
      case "Health & Wellness":
        return Health;
      case "Home & Decor":
        return Furniture;
      case "Sport":
        return Sport;
      case "MartMall":
        return MartMall;
      case "Home & Kitchen":
        return Furniture;
      default:
        return Clothes;
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSellerClick = (seller) => {
    localStorage.setItem("sellerDetails", JSON.stringify(seller));

    const encodedValue = encodeURIComponent(seller?.providerId);
    navigate(`seller?pid=${encodedValue}`, {
      state: {
        category: categoryParam || "All",
      },
    });
  //  navigate(`/${seller?.providerId}`, {
  //     state: {
  //       category: categoryParam || "All",
  //     },
  //   });
  };

  return (
    <>
      {categoriesData === null ? (
        <div className="dotLoader">
          <Loader />
        </div>
      ) : (
        <>
          <Header />
          <section className="products-container">
            <div className="container">
              <div className="products-flex">
                {categoriesData.length > 0 &&
                  categoriesData.map((category, index) => (
                    <div
                      className={`product-item ${
                        category?.title === selectedCategory?.title
                          ? "activeItem"
                          : ""
                      }`}
                      key={index}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <div className="products-img">
                        {category?.image ? (
                          <img src={category?.image} alt={category?.title} />
                        ) : (
                          <img
                            src={getImportedImage(category?.title)}
                            alt={category?.title}
                          />
                        )}
                      </div>
                      <p>{category?.title}</p>
                    </div>
                  ))}
              </div>
            </div>
          </section>
          <section className="store-container">
            <div className="container">
              <div className="store-near-you">
                <h4 className="main_heading">Stores near you</h4>
                <div className="page-bg-block">
                {sellersStatus === "loaded" ? (
                  <div className="page-container">
                    <div className="stores-list">
                      {sellers.length > 0 ? (
                        sellers.map((seller, index) => {
                          const storeName = get(seller, "name", "Not Available");
                          return (
                            <div
                              className="store-item clickable"
                              onClick={() => handleSellerClick(seller)}
                              key={index}
                            >
                              <div className="store-img">
                                <img
                                  alt="seller-pic"
                                  // className={!seller?.photo  && 'default-img' }
                                  src={seller?.photo || BrandLogo}
                                  onError={(event) => {
                                    event.target.onerror = null; // Prevents potential infinite loop
                                    event.target.src = BrandLogo; 
                                    event.target.classList.add('default-img')
                                  }}
                                
                                />
                                {/* {!imageLoaded[index]?.loaded && (
                                  <div className="store-error-msg">
                                    <span className="store-error">{storeName}</span>
                                  </div>
                                )} */}
                              </div>
                              <div className="store_content">
                                <h6>{storeName}</h6>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="nothing-match">
                          <div className="no-cart-img">
                            <img alt="" src={NoData} />
                          </div>
                          <h6 className="text-center">No Stores Found</h6>
                          <h6 className="text-center" style={{whiteSpace:"nowrap"}}>
                            At Your Current Location !!
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                 ) : (
                  <Loader />
                )}
              </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
  
    </>
  );
};

export default HomePage;
