import React from "react";

const DeleteIcon = (props) => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="DeleteOutlineIcon"
  >
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5-1-1h-5l-1 1H5v2h14V4z"></path>
  </svg>
);

export default DeleteIcon;
