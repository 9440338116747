import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {fetchAddressData} from "../api/ApiService";
export const initialState = {
  loading: false,
  hasErrors: false,
  status: false,
  data: [],
  message: "",
  addressStatus:""
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    startAddressLoading: (state, action) => {
      state.loading = action.payload;
    },
    getAddressSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.hasErrors = false;
      state.status = true;
    },
    getAddressFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.status = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddress.pending, (state) => {
        state.hasErrors = true;
        state.addressStatus= "loading"
      })
      .addCase(fetchAddress.fulfilled, (state, action) => {
        // const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
        state.data = action?.payload?.addresses
        state.addressStatus= action?.payload?.success?"loaded":""
        ;
    
      })
      .addCase(fetchAddress.rejected, (state) => {
        state.addressStatus= "error"

      })
   
  },
});
export const fetchAddress = createAsyncThunk(
  'seller/fetchAddress',
  async (body) => {
    const data =  fetchAddressData(body); 
    return data
     
  }
);
export const { startAddressLoading, getAddressSuccess, getAddressFailure } =
  addressSlice.actions;

export default addressSlice.reducer;
