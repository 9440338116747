import React, { useEffect, useState } from "react";
import API2 from "../../api/indexv2";
import { handleError, notify, orderStatus, deliveryStatus } from "../../components/common/utils";
import { GET_ORDER_DETAIL } from "../../api/apiList";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import Header from "../Header/component";
import CancelOrderModal from "./cancelOrderModal";
import TrackOrderModal from "./trackOrderModal";
import ReturnOrderModal from "./returnOrderModal";
import RaiseIssueModal from "./raiseIssueModal";
import Loader from "../../components/common/loader";
import { ToastContainer } from "react-toastify";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  ondcOrderStatus,
  ondcIssueStatus,
  ondcOrderTrack,
  completeIssue,
  esclateIssue
} from "../../slices/ondcPostorderSlice";
import { get } from "lodash";
import { types } from "../../components/common/utils";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [trackModal, setTrackModal] = useState(false);
  const location = useLocation();
  const [RaiseTicket, setRaiseTicket] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [cancelledAndReturned, setCancelledAndReturned] = useState([]);

  const [orderAmounts, setOrderAmounts] = useState({
    TotalCurrentItemsAmount: 0,
    TotalOtherChargesAmount: 0,
  });

  const { ondcOrderstatus,
    ondcIssuestatus,
    ondcTrackOrderstatus,
    ondcComplteIssueStatus,
    ondcEsclateIssueStatus
  } =
    useSelector((state) => ({
      ondcOrderstatus: get(state, "ondcPostorder.ondcOrderstatus", ""),
      ondcIssuestatus: get(state, "ondcPostorder.ondcIssuestatus", ""),
      ondcTrackOrderstatus: get(state, "ondcPostorder.ondcTrackOrderstatus", ""),
      ondcComplteIssueStatus: get(state, "ondcPostorder.ondcComplteIssueStatus", ""),
      ondcEsclateIssueStatus: get(state, "ondcPostorder.ondcEsclateIssueStatus", ""),
    }));
  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = async () => {
    setLoading(true);
    const body = {
      _id: location.state?.orderId,
    };

    try {
      const response = await API2.post(GET_ORDER_DETAIL, body);
      if (response) {
        const data = response?.data?.data
        setOrderData(data);
        setItemData(data?.itemRes);
        const orderItems = data?.itemRes?.filter((item) => item?.type === "Item" && item.quantity.count !== 0)
        const cancelledAndReturned = data?.itemRes.filter((item) => (item.type === "Return" || item.type === "Cancel") && item.quantity.count !== 0)
        const updatedOrderItems = orderItems?.map(item => {
          const issueAlreadyRaised = data?.complaints?.some(complain =>
            complain?.items[0]?.productId === item?.id && complain?.raw?.message?.issue?.status === "OPEN"
          );
          return {
            ...item,
            issueAlreadyRaised
          };
        });
        setOrderItems(updatedOrderItems)
        setCancelledAndReturned(cancelledAndReturned)
        const totalItemAmount = data?.itemRes?.reduce((acc, item) => {
          return acc + (item?.discountedPrice ? parseInt(item?.discountedPrice) : 0);
        }, 0);
        const totalCurrentItemsAmount = data?.itemRes?.reduce((acc, item) => {
          return acc + (item?.discountedPrice && (item?.status === "" || item?.status === "Return_Initiated" || item?.status === "Return_Approved") && item?.quantity?.count > 0 ? parseInt(item?.discountedPrice) : 0);
        }, 0);
        let totalOAmount = 0;
        if (data?.otherChargesItems) {
          totalOAmount = data?.otherChargesItems?.reduce((acc, item) => {
            const price = parseInt(item?.price?.value) ? item?.price?.value : item?.item?.price?.value
            if (!["item"].includes(item["@ondc/org/title_type"])) {
              return acc + parseFloat(price) || 0;
            }
            return acc;
          }, 0);
        }
        setOrderAmounts({
          TotalCurrentItemsAmount: totalCurrentItemsAmount,
          TotalOtherChargesAmount: totalOAmount,
        });
      }
      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
      setOrderData({});
    }
  };

  const getOrdeStatus = () => {
    if (orderData && ondcOrderstatus !== "loading") {
      const res = dispatch(
        ondcOrderStatus({
          orderId: orderData?._id,
        })
      ).then((response) => {
        if (response?.payload?.success) {
          setOrderData((prevState) => ({
            ...prevState,
            orderStatus: response?.payload?.data?.orderStatus,
          }));
        }
        getOrderDetail()
      });
      notify("promise", "Order status fetched successfully!", res);
    }
  };
  const trackOrder = () => {
    dispatch(
      ondcOrderTrack({
        orderId: orderData?._id,
      })
    )
      .then((response) => {
        if (response?.payload?.data?.trackingDetails?.details?.url) {
          notify("success", "Track successfully!");
          const trackingUrl = response?.payload?.data?.trackingDetails?.details?.url;
          window.open(trackingUrl);
        } else {
          notify(
            "error",
            "Cannot proceed with your request now! Please try again later."
          );
        }
      })
      .catch((error) => {
        notify(
          "error",
          "An error occurred while processing your request. Please try again later."
        );
      });
  };

  const getIssueStatus = (issueId) => {
    if (orderData && ondcOrderstatus !== "loading") {
      dispatch(
        ondcIssueStatus({
          orderId: orderData?._id,
          issue_id: issueId,
        })
      ).then((response) => {
        if (response?.payload?.result?.context?.action === "issue_status") {
          notify("success", "Issue status updated successfully!");
          getOrderDetail()
          // setResActions(response?.payload?.complaints?.raw?.message?.issue?.issue_actions?.respondent_actions)
        }
      });
    }
  };

  const closeIssue = (issueId) => {
    if (orderData && ondcOrderstatus !== "loading") {
      dispatch(
        completeIssue({
          orderId: orderData?._id,
          issueId: issueId,
        }),

      ).then((response) => {
        if (response?.payload?.result?.context?.action) {
          getOrderDetail();
          notify("success", "Issue Marked Resolved successfully!");
        }
      });
    }
  };

  const escalateIssue = (issueId) => {
    if (orderData && ondcOrderstatus !== "loading") {
      dispatch(
        esclateIssue({
          orderId: orderData?._id,
          issueId: issueId,
        })
      ).then((response) => {
        if (response?.payload?.result?.context?.action) {
          getOrderDetail()
          notify("success", "Issue Marked Not Resolved successfully!");
        }
      });
    }
  };

  function getSubcategoryLabel(subcategoryValue) {
    for (const type of types) {
      const subcategory = type.subcategories.find(
        (sub) => sub.value === subcategoryValue
      );
      if (subcategory) {
        return subcategory.label;
      }
    }
    return 'Subcategory not found';
  }

  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        position="top-right"
      />
      <Header />
      {loading && !orderData?._id ? (
        <div className="dotLoader">
          <Loader />
        </div>
      ) : (
        <section className="order-details-section">
          <div className="buyercheckout-heading">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-9">
                  <div className="d-flex align-items-center justify-content-between">
                    <h1 className="title">Orders Details</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-details-contaier">
            <div className="container">
              <div className="cart-details">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="cart-details-container">
                      <div className="summary-card">
                        <div
                          className="summary-flex py-2"
                        // style={{ borderBottom: "1px solid #d3d3d3" }}
                        >
                          <p className="m-0">
                            <b>Delivery Status</b>
                          </p>
                          <p className="m-0">
                            <b> <span className=" btn-DarkBlue">
                              {deliveryStatus(orderData?.deliveryStatus)}
                            </span></b>
                          </p>
                        </div>
                        <div className="summary-heading">
                          <h4 className="summary-title mb-2">
                            <span>Order Number</span>:{orderData?.orderId}
                          </h4>
                          <h4 className="summary-title mb-2">
                            <span>Seller</span>: {orderData?.delivery?.['@ondc/org/provider_name']}
                          </h4>
                          <div className="d-flex gap-1 align-items-center justify-content-between">
                            <p className="order-on-title">
                              Ordered On:
                              {dayjs(orderData?.createdAt).format(
                                "MMM D, YYYY h:mm A"
                              )}{" "}
                              | Payment: Prepaid
                            </p>
                            <span className=" btn-DarkBlue">
                              {orderStatus(orderItems?.length > 0 && orderItems.every(item => item?.quantity?.count !== 0) ? orderData?.orderStatus : "Cancelled")}
                            </span>
                          </div>
                        </div>
                        <div className="summary-content p-0">
                          {orderItems?.length > 0 && orderItems?.map((item, index) => {
                            return (
                              <div key={index} className="summary-content">
                                <div className="d-flex align-items-center gap-2">
                                  {item?.productImages &&
                                    item?.productImages
                                      ?.length > 0 && (
                                      <img
                                        src={
                                          item.productImages?.[0]
                                        }
                                        alt={item?.title}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          marginRight: "10px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                    )}

                                  {item?.productName && <p>{item?.productName}</p>}
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  {item?.quantity?.count && (
                                    <p className="text-end">
                                      {item?.quantity?.count}
                                      * Base Price
                                    </p>
                                  )}

                                  <p>₹{item?.discountedPrice}</p>
                                </div>
                              </div>

                            );
                          })}
                          {cancelledAndReturned?.length > 0 && cancelledAndReturned?.map((item, index) => {
                            return (
                              <div key={index} className="summary-content">
                                <div className="d-flex align-items-center gap-2">
                                  {item?.productImages &&
                                    item?.productImages
                                      ?.length > 0 && (
                                      <img
                                        src={
                                          item.productImages?.[0]
                                        }
                                        alt={item?.title}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          marginRight: "10px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                    )}

                                  {item?.productName && <p>{item?.productName}</p>}
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  {item?.quantity?.count && (
                                    <p className="text-end">
                                      {item?.quantity?.count}
                                      * Base Price
                                    </p>
                                  )}

                                  <p>₹{item?.discountedPrice}</p>
                                </div>
                                <p><b>{item?.status}</b></p>
                                {
                                  (item?.logisticsProviderName && item?.status !== "Return_Delivered") &&
                                  <div className="summary-flex py-2">
                                    <p className="m-0">Return will be picked up by : {item?.logisticsProviderName}</p>
                                    {item?.pickUpOTP &&
                                      <p className="m-0">
                                        <b>PICK UP OTP : {item?.pickUpOTP}</b>
                                      </p>
                                    }
                                  </div>
                                }
                                {
                                  item?.refundStatus &&
                                  <div>
                                    <p>Refund Status:{item?.refundStatus === "Completed" ? " Amount Refunded" : "Pending"}</p>
                                    <p>Refund Amount: {item?.refundAmount}</p>
                                  </div>
                                }
                              </div>

                            );
                          })}
                          {orderItems?.length > 0 &&
                            <div
                              className="summary-flex py-2"
                              style={{ borderBottom: "1px solid #d3d3d3" }}
                            >
                              <p className="m-0">
                                <b>Sub Total</b>
                              </p>
                              <p className="m-0">
                                <b>₹{orderAmounts?.TotalCurrentItemsAmount}</b>
                              </p>

                            </div>
                          }
                          {orderData?.otherChargesItems?.map((item, index) => {
                            const rtoCharges = item["@ondc/org/item_id"] !== orderData?.delivery?.id
                            return (
                              item["@ondc/org/title_type"] !== "item" &&
                              <>
                                <div key={index} className="summary-content border-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    {item?.title && <p className="m-0">{`${item?.title} ${rtoCharges ? "(RTO)" : ""}`}</p>}
                                    <p className="m-0">₹{parseInt(item.price.value) ? item.price.value : parseInt(item?.item?.price?.value) ? item.item.price.value : 0}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                          <div
                            className="summary-flex py-2 mt-3"
                            style={{
                              borderBottom: "1px solid #d3d3d3",
                              borderTop: "1px solid #d3d3d3",
                            }}
                          >
                            <p className="m-0">
                              <b>Sub Total</b>
                            </p>
                            <p className="m-0">
                              <b>₹{orderAmounts?.TotalOtherChargesAmount?.toFixed(2)}</b>
                            </p>
                          </div>
                          <div className="summary-footer">
                            <div className="summary-flex">
                              <p>  <b>Total Amount </b></p>
                              <p>  <b>₹{orderData?.totalAmountPaid}</b></p>
                            </div>
                          </div>
                          {cancelledAndReturned?.length > 0 &&
                            <div
                              className="summary-flex py-2"
                              style={{ borderBottom: "1px solid #d3d3d3" }}
                            >
                              <p className="m-0">
                                <b>Total Refund Amount</b>
                              </p>
                              <p className="m-0">
                                <b>₹{orderData?.totalRefundAmount}</b>
                              </p>
                            </div>
                          }
                          <div className="summary-footer-btn m-0">
                            {
                              (orderData?.orderStatus === "Accepted" || orderData?.orderStatus === "Created")
                              && itemData?.every(item => item?.isCancellable === true) &&
                              <button
                                className="btn btn-danger btn-120"
                                onClick={() => {
                                  setCancelModal(true);
                                }}
                                disabled={ondcOrderstatus === "loading"}
                              >
                                Cancel Order
                              </button>
                            }
                            {
                              orderData?.orderStatus !== "Cancelled" &&
                              (
                                <>
                                  <button
                                    className="btn btn-outline btn-120"
                                    onClick={() => {
                                      setRaiseTicket(true);
                                    }}
                                    disabled={ondcOrderstatus === "loading"}
                                  >
                                    Raise Issue
                                  </button>
                                </>
                              )}
                            {orderData?.orderStatus === "Completed" && orderItems?.length > 0 &&
                              itemData?.some(item => item?.isReturnable === true) &&
                              (
                                <>
                                  <button
                                    onClick={() => {
                                      setReturnModal(true);
                                    }}
                                    className="btn btn-danger btn-120"
                                    disabled={ondcOrderstatus === "loading"}
                                  >
                                    Return
                                  </button>
                                </>
                              )}
                            {(orderData?.deliveryStatus === "Order-picked-up" || orderData?.deliveryStatus === "Out-for-delivery") &&
                              <button
                                className="btn btn-outline btn-120"
                                onClick={() => trackOrder()}
                                disabled={ondcOrderstatus === "loading"}
                              >
                                {ondcTrackOrderstatus === "loading"
                                  ? "Please Wait..."
                                  : "Track Order"}
                              </button>}
                            <button
                              className="btn btn-outline btn-120"
                              onClick={() => getOrdeStatus()}
                            >
                              {ondcOrderstatus === "loading"
                                ? "Please Wait..."
                                : "Get Status"}
                            </button>

                            {/* <button className="btn btn-blue ">
                            Download Invoice
                          </button> */}
                            {/* <button className="btn btn-blue ">
                            <Phone /> Call Vijendra Grocery
                          </button> */}
                          </div>
                        </div>
                      </div>
                      {/* {cancelledAndReturned?.map((item, index) => (
                        <div className="order-details-content">
                          <div className="customer-details">
                            <div className="d-flex align-items-center gap-2">
                              <img
                                alt="productImages"
                                src={item?.productImages?.[0]}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                }}
                              />
                              <div>
                                <p>{item?.productName}</p>
                                <p><b>Price : {item?.discountedPrice}</b></p>
                              </div>
                            </div>
                            <p>QTY: {item?.quantity?.count}</p>
                            <p><b>{item?.status}</b></p>
                            {
                              (item?.logisticsProviderName && item?.status !== "Return_Delivered") &&
                              <div className="summary-flex py-2">
                                <p className="m-0">Return will be picked up by : {item?.logisticsProviderName}</p>
                                {item?.pickUpOTP &&
                                  <p className="m-0">
                                    <b>PICK UP OTP : {item?.pickUpOTP}</b>
                                  </p>
                                }
                              </div>
                            }
                            {
                              item?.refundStatus &&
                              <div>
                                <p>Refund Status:{item?.refundStatus === "Completed" ? " Amount Refunded" : "Pending"}</p>
                                <p>Refund Amount: {item?.refundAmount}</p>
                              </div>
                            }
                          </div>
                        </div>
                      ))} */}
                      {orderData?.delivery &&
                        <div className="order-details-content">
                          <div className="customer-details">
                            <div>
                              <h5>Delivered To</h5>
                              <h6>
                                {orderData?.delivery?.end?.location?.address?.name},{" "}
                                {orderData?.delivery?.end?.contact?.phone}
                              </h6>
                              <p className="m-0">
                                {orderData?.delivery?.end?.location?.address?.locality}
                              </p>
                            </div>
                          </div>
                        </div>
                      }
                      {orderData?.complaints && orderData?.complaints.length > 0 && (
                        <div className="card-accordion">
                          <div className="accordion" id="accordionExample">
                            {orderData?.complaints.map((complaint, complaintIndex) => {
                              const issue = complaint.raw?.message?.issue
                              const resActions = complaint?.raw?.message?.issue?.issue_actions?.respondent_actions
                              const complainant_actions = complaint.raw?.message?.issue?.issue_actions?.complainant_actions
                              const respondent_actions = complaint.raw?.message?.issue?.issue_actions?.respondent_actions
                              const description = complaint?.raw?.message?.issue?.description
                              const resolution = complaint?.raw?.message?.issue?.resolution
                              return (
                                <div className="accordion-item" key={complaintIndex}>
                                  <h2 className="accordion-header mb-3" id={`heading${complaintIndex}`}>
                                    <button
                                      className="accordion-button p-0"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${complaintIndex}`}
                                      aria-expanded={complaintIndex === 0 ? "true" : "false"}
                                      aria-controls={`collapse${complaintIndex}`}
                                    >
                                      Complaints {complaintIndex + 1}
                                    </button>
                                  </h2>
                                  <div className="d-flex align-items-center gap-2">
                                    {complaint?.items && complaint?.items.length > 0 &&
                                      complaint.items.map((item, index) => (
                                        <div key={index}>
                                          {item?.productImages && item?.productImages.length > 0 && (
                                            <img
                                              src={item?.productImages[0]}
                                              alt={item?.title}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                marginRight: "10px",
                                                marginBottom: "10px",
                                              }}
                                            />
                                          )}
                                          <br />
                                          <p>{item?.productName}</p>
                                          <p key={index} className="text-end"> quantity: {item?.quantity}</p>
                                        </div>
                                      ))
                                    }
                                  </div>
                                  <div className="complaints-flex">

                                    <div className="complaints-items">
                                      <div className="d-flex gap-2 align-items-center">
                                        <div>
                                          <h1>
                                            {issue?.category} -
                                            {getSubcategoryLabel(issue.sub_category)}
                                          </h1>
                                          <p>
                                            Issue raised on {dayjs(issue?.created_at).format("MMM D, YYYY h:mm A")}
                                          </p>
                                          <p>Issue Id: {complaint?.issue_id}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="complaints-status">
                                      <p><b>Status:</b> <span className="green">{issue?.status}</span></p>
                                      <p><b>Expected response time:</b> {dayjs(issue?.created_at).add(1, "hour").format("MMM D, YYYY h:mm A")}</p>
                                      <p><b>Expected resolution time:</b> {dayjs(issue?.created_at).add(1, "day").format("MMM D, YYYY h:mm A")}</p>
                                    </div>
                                  </div>
                                  <div
                                    id={`collapse${complaintIndex}`}
                                    className={`accordion-collapse collapse ${complaintIndex === 0 ? "show" : ""}`}
                                    aria-labelledby={`heading${complaintIndex}`}
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body p-0">
                                      <div className="complaints-msg">
                                        <p><b>Subject:</b> {description?.short_desc}</p>
                                      </div>
                                      <div className="complaints-msg">
                                        <p><b>Message:</b> {description?.long_desc}</p>
                                      </div>
                                      <div className="complaints-msg">
                                        <p><b>Action Taken</b></p>
                                      </div>
                                      <div className="Complaint-created-block">
                                        {complainant_actions &&
                                          complainant_actions.filter(action => action?.complainant_action !== "CLOSE")
                                            .map((item, index) => (
                                              <div className="Complaint-created-flex" key={index}>
                                                <div className="d-flex gap-2 align-items-start">
                                                  <div className="created complaint-green">
                                                    <p></p>
                                                  </div>
                                                  <div>
                                                    <p><b>{item.short_desc}</b></p>
                                                    <p>Updated by: {item.updated_by?.person?.name}, {complaint.raw?.context?.bap_id}</p>
                                                    <p>Updated at: {dayjs(item.updated_at).format("MMM D, YYYY h:mm A")}</p>
                                                  </div>
                                                </div>
                                                <div>
                                                  <p><b>Actions: </b>{item.complainant_action}</p>
                                                </div>
                                              </div>
                                            ))
                                        }
                                        {respondent_actions &&
                                          respondent_actions.map((action, index) => (
                                            <div className="Complaint-created-flex" key={index}>
                                              <div className="d-flex gap-2 align-items-start">
                                                <div className={index !== complaint.raw.message.issue.issue_actions.respondent_actions.length - 1 || action.respondent_action === "RESOLVED" ? "created complaint-green" : "created complaint"}>
                                                  <p></p>
                                                </div>
                                                <div>
                                                  <p><b>{action.short_desc}</b></p>
                                                  <p>Updated by: {action.updated_by?.person?.name}, {complaint.raw?.context?.bpp_id}</p>
                                                  <p>Updated at: {dayjs(action.updated_at).format("MMM D, YYYY h:mm A")}</p>
                                                </div>
                                              </div>
                                              <div>
                                                <p><b>Actions: </b>{action.respondent_action}</p>
                                              </div>
                                            </div>
                                          ))
                                        }
                                        {complainant_actions &&
                                          complainant_actions.filter(action => action?.complainant_action === "CLOSE")
                                            .map((action, index) => (
                                              <div className="Complaint-created-flex" key={index}>
                                                <div className="d-flex gap-2 align-items-start">
                                                  <div className={index !== complainant_actions.length - 1 ? "created complaint-green" : "created complaint"}>
                                                    <p></p>
                                                  </div>
                                                  <div>
                                                    <p><b>{action?.short_desc}</b></p>
                                                    <p>Updated by: {action?.updated_by?.person?.name}, {complaint?.raw?.context?.bpp_id}</p>
                                                    <p>Updated at: {dayjs(action?.updated_at).format("MMM D, YYYY h:mm A")}</p>
                                                  </div>
                                                </div>
                                                <div>
                                                  <p><b>Actions: </b>{action?.complainant_action}</p>
                                                </div>
                                              </div>
                                            ))
                                        }
                                      </div>
                                      {resolution &&
                                        <div className="Respondent">
                                          <div>
                                            {resolution?.short_desc && <p><b>Resolution :  {resolution?.short_desc} </b></p>}
                                            {resolution?.long_desc && <p>Description: {resolution?.long_desc}</p>}
                                            {resolution?.action_triggered && <p>Action Taken: {resolution?.action_triggered}</p>}
                                            {resolution?.refund_amount && <p>Refund Amount: {resolution?.refund_amount}</p>}
                                          </div>

                                        </div>
                                      }
                                      <div className="Respondent">
                                        <div>
                                          <p><b>Respondent  </b></p>
                                          <p>Email: {resActions?.[0]?.updated_by?.contact?.email}</p>
                                          <p>Phone: {resActions?.[0]?.updated_by?.contact?.phone}</p>
                                        </div>
                                        <div>
                                          <button
                                            onClick={() => getIssueStatus(issue?.id)}
                                            className="btn btn-outline"
                                            disabled={ondcIssuestatus === "loading"}
                                          >
                                            {ondcIssuestatus === "loading" ? "Please Wait..." : "Get Status"}
                                          </button>
                                        </div>
                                        {resActions?.slice(-1)[0]?.respondent_action === "RESOLVED" && complainant_actions.some(action => action?.complainant_action === "CLOSE") === false &&
                                          <div>
                                            <button
                                              onClick={() => escalateIssue(complaint._id)}
                                              className="btn btn-outline"
                                              disabled={ondcEsclateIssueStatus === "loading"}
                                            >
                                              {ondcEsclateIssueStatus === "loading" ? "Please Wait..." : "Escalate Issue"}
                                            </button>
                                          </div>
                                        }
                                        {resActions?.slice(-1)[0]?.respondent_action === "RESOLVED" && !complainant_actions.some(action => action?.complainant_action === "CLOSE") &&
                                          <div>
                                            <button
                                              onClick={() => closeIssue(complaint._id)}
                                              className="btn btn-outline"
                                              disabled={ondcComplteIssueStatus === "loading"}

                                            >
                                              {ondcComplteIssueStatus === "loading" ? "Please Wait..." : "Mark Resolved"}
                                            </button>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {cancelModal && (
        <CancelOrderModal
          setCancelModal={setCancelModal}
          getOrderDetail={getOrderDetail}
          cancelModal={cancelModal}
          orderId={orderData?._id}
        ></CancelOrderModal>
      )}
      {returnModal && (
        <ReturnOrderModal
          setReturnModal={setReturnModal}
          getOrderDetail={getOrderDetail}
          returnModal={returnModal}
          orderId={orderData?._id}
          itemData={orderItems}
        ></ReturnOrderModal>
      )}
      {RaiseTicket && (
        <RaiseIssueModal
          setRaiseTicket={setRaiseTicket}
          getOrderDetail={getOrderDetail}
          RaiseTicket={RaiseTicket}
          orderId={orderData?._id}
          itemData={orderItems}
        ></RaiseIssueModal>
      )}

      {trackModal && (
        <TrackOrderModal
          setTrackModal={setTrackModal}
          trackModal={trackModal}
        ></TrackOrderModal>
      )}
    </>
  );
};
export default OrderDetails;
