import React ,{useMemo ,useState,useEffect}from "react";
import { GoogleMap, useJsApiLoader ,Marker} from "@react-google-maps/api";
import "./styles.scss";
import { Location } from "../svgicons";

function Map(props) {
  const [userLat, setUserLat] = useState();
  const [userLong, setUserLong] = useState();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setUserLat(position.coords.latitude);
      setUserLong(position.coords.longitude);
      console.log(position.coords.latitude, position.coords.longitude);
    });
  }, []);

  const passAr = (latlng) => {
    var objmap = {};
    objmap.latitude = latlng.latitude;
    objmap.longitude = latlng.longitude;
    props.func(objmap);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "15px",
  };

  const center = useMemo(
    () => ({
      lat: props.currLatlong1.latitude ? props.currLatlong1.latitude : 22.7196,
      lng: props.currLatlong1.longitude ? props.currLatlong1.longitude : 75.8577,
    }),
    [props.currLatlong1]
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setTimeout(() => {
      setZoom(20);
    }, 100);
    // map.setZoom(15)
    setMap(map);
  }, [center]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
    <div className="custom-zoom-controls">
        <button onClick={() => map.setZoom(map.getZoom() + 1)} className="control-btn">+</button>
        <button onClick={() => map.setZoom(map.getZoom() - 1)} className="control-btn">-</button>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        defaultCenter={center}
        zoom={zoom}
        onDragEnd={() => {
          passAr({
            latitude: map.getCenter().lat(),
            longitude: map.getCenter().lng(),
          });
        }}
        options={{ gestureHandling: "greedy" }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {props.isMarkerShown && <Marker position={{ lat: 22.7196, lng: 75.8577 }} />}
      </GoogleMap>
      
      <div className="markerbg">
        <h4>Your Order will be delivered here</h4>
        <p>Place the pin accurately</p>
      </div>
      <button className="current-btn-locaton" onClick={() => {
        map.panTo({ 
          lat: userLat, 
          lng: userLong
         })}}><Location/></button>
      
    </>
  ) : (
    <></>
  );
}
export default Map;