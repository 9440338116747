import React from "react";

const LeftArrow = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
       fill="currentColor"
      d="M10.47 19.281l-6.75-6.75a.749.749 0 010-1.061l6.75-6.75a.75.75 0 111.06 1.061l-5.47 5.47h14.69a.75.75 0 110 1.5H6.06l5.47 5.469a.75.75 0 11-1.06 1.061z"
    ></path>
  </svg>
);


export default LeftArrow;
