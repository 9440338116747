import React, { useEffect, useState } from "react";

import API2 from '../../api/indexv2';
import { handleError,orderStatus } from "../../components/common/utils";
import { GET_BUYER_ORDER } from "../../api/apiList";
import Loader from "../../components/common/loader";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import NoCart from "../../images/no-cart.svg"
// import HoneyPure from "../../images/honey-pure.png";
import "./styles.scss"

const Order = () => {
  const [orderData, setOrderData] = useState([]);
  // const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem("user")) || ""

  const Orders = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user")) || ""
    const body = {
      // page: page,
      page: 1,
      limit: 50,
      userId: user?._id,
    };

    try {
      const response = await API2.post(GET_BUYER_ORDER, body);
      if (response) {
        // setCount(response?.data?.data?.totalDocs);
        setOrderData(response?.data?.data?.results);
      }
      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
      setOrderData([]);
    }
  };
  
  useEffect(() => {
    Orders();
  }, []);


  return (
    <>
      <div >
        {loading ? (
          <div className="dotLoader">
            <Loader />
          </div>
        ) : (
          <>
            {orderData && orderData?.length > 0 ? (

              <div className="my-orders" >
                {orderData.map((order, index) => {
                    const items = order?.quote?.breakup.filter(item => item["@ondc/org/title_type"] === "item");
                  return (
                    <div  key={index} className="order-card" onClick={() => {
                      navigate("/buyer-order-details", {
                        state: { orderId: order?._id },
                      });
                    }}
                    >
                      <a href="/buyer-order-details" className="order-card-link">
                        <div className={order?.orderStatus === "Created" ? "order-product-img yellowOrder" : order?.orderStatus === "Cancelled" ? "order-product-img RedOrder" : order?.orderStatus === "In-progress" ? "order-product-img BlueOrder" : order?.orderStatus === "Accepted" ? "order-product-img LightGreenOrder" : order?.orderStatus === "Completed" ? "order-product-img DarkGreenOrder" : "order-product-img yellowOrder"}>
                        {orderStatus(order?.orderStatus)}
                        </div>
                           {/* <div className="itemImag">
                              {order?.items[0]?.productImages[0] &&(
                              <img src={order?.items[0]?.productImages[0]} /> 
                            )}
                            
                                 </div> */}
                        <div className="order-product-details">
                         {/* <h6>{order?.orderId}</h6> */}
                       {/* {order?.quote?.breakup.map((item, index) => {
                        return (
                          item["@ondc/org/title_type"] === "item" && (
                              <div key={index} >
                                {item?.title && <p>{item?.title}</p>}
                              </div>
                          )
                        );
                      })} */}
                         {/* {items.map((item, index) => ( */}
                            <div key={index}>
                              {items[0]?.title && <p>{items[0]?.title}</p>}
                            </div>
                          {/* ))} */}
                          <div className="show-more-item">
                          {items.length > 1 && ( "... Show more items")}
                          </div>
                          <p className="product-amount">Order Amount ₹{order?.quote?.price?.value}</p>
                          <span>{dayjs(order?.createdAt).format(
                            "MMM D, YYYY h:mm A"
                          )}</span>
                        </div>
                      </a>
                    </div>

                  );
                })}
              </div>

            ) : (
              <div className="nothing-match">
              <div className="no-cart-img">
                <img alt="" src={NoCart}/>
              </div>
            <h6 className="text-center">No Orders Found !!</h6>
            </div>
              // <div className="notfounderror">
              //   <h1 className="no-found-data">Order not found</h1>
              // </div>
            )}
          </>
        )}
      </div>


    </>
  );
};
export default Order;

