import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./styles.scss";
import crossIcon from "../../images/cross-icon.svg"
import QRCode from "react-qr-code";

const QrPopup = (props) => {
  return (
    <Modal
    isOpen={props?.qrPopUp}
    toggle={() => {
      props?.setQrPopUp(false);
    }}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    size="sm"
    className="qr-code-modal"
  >
    <ModalHeader>
      Scan the Store QR Code
      <div>
        <img
          alt="crossIcon"
          src={crossIcon}
          onClick={() => {
            props?.setQrPopUp(false);
          }}
        />
      </div>
    </ModalHeader>
    <ModalBody>
      <QRCode
        className="w-100"
        title="Kiko"
        value={window?.location?.href}
        bgColor={"#FFFFFF"}
        fgColor={"#000000"}
        size={256}
      />
    </ModalBody>
  </Modal>
  );
};
export default QrPopup;
