import React, { Fragment } from 'react'
import { toast } from 'react-toastify';
import { get } from 'lodash'
import 'react-toastify/dist/ReactToastify.css';


export const sellerDetails = () => {
  try {
    return JSON.parse(localStorage.getItem("sellerDetails"))
  } catch (error) {
    return null
  }
}



export const notify = (type, message, promise = null) => {
  if (type === "promise") {
    toast.promise(
      promise,
      {
        pending: 'Please Wait ...',
        success: message || 'Success 👌',
        error: message || 'Error 🤯',
      },
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 900,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
      }
    );
  }
  else {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}
export const handleError = error => {
  if (error?.response?.data?.message === 'Unauthorized access') {
    notify('error', error.response.data.message)
  }

  if (error?.response && error?.response.data) {
    if (error?.response?.data?.error) {
      const err = get(error, 'response.data.error', '')
      if (
        get(err, 'code', '') === 401 &&
        get(err, 'stack', '') === 'Unauthorized access'
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // window.location.reload();
        return
      }
      toast(
        ({ closeToast }) =>
          typeof err === 'string'
            ? err.includes('not a valid phone number') ||
              err.includes('is not an SMS enabled phone number')
              ? 'That phone number is not recognized'
              : err
            : Object.keys(err).map((element, index) => (
              <Fragment key={index}>
                {err[element]}
                <br />
              </Fragment>
            )),
        {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      )
    } else {
      notify('error', error.response.data.message)
    }
  } else if (error.message) {
    notify(
      'error',
      error.message === 'Network Error'
        ? 'You are offline, please check your internet connection'
        : error.message
    )
  } else {
    notify('error', 'Something went wrong!')
  }
}

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() =>
      // alert(`${text} copied to clipboard!`)
      notify("success", `${text} copied to clipboard!`)
    )
    .catch((error) => notify("error", "Failed to copy text"));
};

export function getDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180; // Convert degrees to radians
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
    Math.cos((lat2 * Math.PI) / 180) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

export const isStoreOpen = (storeDetails) => {
  if (
    !storeDetails ||
    !storeDetails.locations ||
    storeDetails.locations.length === 0
  ) {
    return false;
  }

  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // Get current day (0-6, where 0 is Sunday)
  const currentTime =
    currentDate.getHours() * 100 + currentDate.getMinutes(); // Get current time in HHMM format

  const { label, range } = storeDetails.locations[0].time || {};
  if (label === "close") {
    return false;
  } else if (label === "open") {
    return true;
  } else if (label === "enable") {
    const holidays = storeDetails.locations[0].schedule?.holidays || [];
    const isHoliday = holidays.includes(
      currentDate.toISOString().split("T")[0]
    );
    if (isHoliday) {
      return false;
    }

    const openingDays = (storeDetails.locations[0].time?.days || "")
      .split(",")
      .map((day) => parseInt(day));
    if (!openingDays.includes(currentDay)) {
      return false;
    }
    const { start, end } = range || {};
    const startTime = parseInt(start);
    const endTime = parseInt(end);
    if (
      !isNaN(startTime) &&
      !isNaN(endTime) &&
      currentTime >= startTime &&
      currentTime <= endTime
    ) {
      return true;
    }
  }

  return false;
};


export const errorCodeMessages = (code) => {

  const errorMessages = {
    "40002": "Out of stock",
    "40003": "Quote unavailable",
    "40004": "Payment type not supported",
    "40005": "Tracking not enabled",
    "40006": "Fulfilment agent unavailable",
    "40007": "Change in item quantity",
    "40008": "Change in quote",
    "40009": "Maximum order qty exceeded",
    "30009": "Location Serviceability error."
  };

  return errorMessages[code] || "Uh-oh! Seller facing a technical issue"
};
export const orderStatus = (status) => {
  const statusMessages = {
    "Accepted": " Order Accepted",
    "Created": "Order Placed",
    "In-progress": "In Progress",
    "Completed": "Delivered",
    "Cancelled": "Order Cancelled"
  };
  return statusMessages[status] || status;
};

export const deliveryStatus = (status) => {
  const statusMessages = {
    "Packed": "Parcel Packed",
    "Pending": "Delivery Pending",
    "Order-picked-up": "Parcel Picked Up",
    "Out-for-delivery": "Parcel Out For Delivery",
    "Order-delivered": "Parcel Delivered"
  };
  if (statusMessages[status]) {
    return statusMessages[status]
  }
  else if (status && status !== '' && status?.length > 0) {
    return status
  }
  else {
    return "Delivery Pending"
  }
};

export const formatAndLocalizeText = (text) => {
  const textObject = {
    "Kilogram": "kg",
    "kilogram": "kg",
    "gram": "g",
    "millilitre": "ml"

  };
  return textObject[text] || text
};


export const types = [
  {
    value: "ORD01",
    label: "ORDER",
    subcategories: [
      { value: "ORD01", label: "Order not received" },
      { value: "ORD02", label: "Quality issue" },
      { value: "ORD03", label: "Delayed delivery" },
      { value: "ORD04", label: "Invoice missing" },
      { value: "ORD05", label: "Store not responsive" },
    ],
  },
  {
    value: "ITM01",
    label: "ITEM",
    subcategories: [
      { value: "ITM01", label: "Missing items" },
      { value: "ITM02", label: "Quantity issue" },
      { value: "ITM03", label: "Item mismatch" },
      { value: "ITM04", label: "Quality issue" },
      { value: "ITM05", label: "Expired item" },
      { value: "ITM06", label: "Incorrectly marked as returned" },
    ],
  },
  {
    value: "FLM01",
    label: "FULFILLMENT",
    subcategories: [
      { value: "FLM01", label: "Wrong delivery address" },
      { value: "FLM02", label: "Delay in delivery" },
      { value: "FLM03", label: "Delayed delivery" },
      { value: "FLM04", label: "Packaging" },
      { value: "FLM07", label: "Package info mismatch" },
      { value: "FLM08", label: "Incorrectly marked as delivered" },
    ],
  },
  {
    value: "PMT01",
    label: "PAYMENT",
    subcategories: [
      { value: "PMT01", label: "Refund not received" },
      { value: "PMT02", label: "Underpaid" },
      { value: "PMT04", label: "Not paid" },
    ],
  },
];
