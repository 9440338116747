import React from "react";
import "react-toastify/dist/ReactToastify.css";
// import { Modal } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import OtpInput from "react-otp-input";
import Logo from "../../images/kikoimg.svg";
import BuyerSliderImg from "../../images/buyer-slider-img.png";
import "./styles.scss";

const Loginpage = (props) => {
  const sliderSetting = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  return (
    <>
      <section className="login-section">
        <div className="auth-row">
          <div className="auth-col auth-is-dark">
            <div className="auth-slider-container">
              <Slider className="auth-slider" {...sliderSetting}>
                <div>
                  <img alt ="BuyerSliderImg" src={BuyerSliderImg} className="auth-img" />
                </div>
                <div>
                  <img alt ="BuyerSliderImg" src={BuyerSliderImg} className="auth-img" />
                </div>
                <div>
                  <img alt ="BuyerSliderImg" src={BuyerSliderImg} className="auth-img" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="auth-col  d-flex align-items-center justify-content-center">
            <div className="auth-wrapper">
              <div className="auth-header">
                  <img  alt ="Logo" src={Logo} />
                <h1 className="auth-title">Sign Up</h1>
                <p className="auth-subtitle">
                  Get access to your Orders, Wishlist and Recommendations
                </p>
              </div>
              <div className="auth-form-container">
               
                <div className="did-floating-label-content">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=" "
                  />
                  <label className="did-floating-label">Full Name</label>
                </div>
                <div className="did-floating-label-content">
                  <input
                    className="did-floating-input"
                    type="email"
                    placeholder=" "
                  />
                  <label className="did-floating-label">Email ID </label>
                </div>
                <div className="did-floating-label-content did-error-input">
                  <input
                    className="did-floating-input"
                    type="number"
                    placeholder=" "
                  />
                  <label className="did-floating-label">Mobile Number</label>
                </div>
                {/* <div className="did-floating-label-content">
                    <label className="mb-3">Change Number</label>
                    <div className="InputFlex">
                        <div className="inputflexBlock">
                          <OtpInput
                            pattern="[0-9]*"
                            inputMode="numeric"
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </div>
                      <p
                        type="submit"
                        className="resend-otp"
                      >
                        Resend OTP
                      </p>
                </div> */}
                <div className="did-floating-label-content">
                  <button type="button" className="btn btn-primary btn-sm w-100">
                    Sign Up
                  </button>
                  {/* <button type="button" className="btn btn-primary btn-sm w-100">
                    Get OTP
                  </button> */}
                </div>
                {/* <div className="auth-divider ">Or Continue with</div> */}
                {/* <div className="did-floating-label-content text-center">
                    <button  type="button" className="btn btn-outline"><span className="auth-third-icon"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40"><path fill="#FFC107" d="M39.611 16.083H38V16H20v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12S13.373 8 20 8c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C30.046 2.053 25.268 0 20 0 8.955 0 0 8.955 0 20s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"></path><path fill="#FF3D00" d="M2.306 10.691l6.571 4.819C10.655 11.108 14.961 8 20 8c3.06 0 5.842 1.154 7.961 3.039l5.657-5.657C30.046 2.053 25.268 0 20 0 12.318 0 5.656 4.337 2.306 10.691z"></path><path fill="#4CAF50" d="M20 40c5.166 0 9.86-1.976 13.409-5.191l-6.19-5.238a11.91 11.91 0 01-7.22 2.43c-5.201 0-9.618-3.317-11.282-7.946L2.195 29.08C5.505 35.557 12.227 40 20 40z"></path><path fill="#1976D2" d="M39.611 16.083H38V16H20v8h11.303a12.04 12.04 0 01-4.087 5.571l.003-.002 6.19 5.238C32.971 35.205 40 30 40 20c0-1.341-.138-2.65-.389-3.917z"></path></svg></span>Google</button>
                </div> */}
                <div className="auth-footer">
                  Already have an account? <a href="/signin">Sign In</a>
                </div>
                {/* <div className="auth-footer">
                Don’t have an account? <a href="#">Sign Up</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Loginpage;
