// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Roboto-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Roboto-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Roboto-Light.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Roboto-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Roboto-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Roboto-Medium.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Roboto-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./Roboto-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./Roboto-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("./Roboto-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("./Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_13___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/fonts/Roboto/stylesheet.css"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,4CAAA;EACA,4LAAA;EAGA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAFJ;AAMA;EACI,qBAAA;EACA,4CAAA;EACA,qPAAA;EAIA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAPJ;AAUA;EACI,qBAAA;EACA,4CAAA;EACA,+LAAA;EAGA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAVJ;AAeA;EACI,qBAAA;EACA,6CAAA;EACA,yPAAA;EAIA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAhBJ","sourcesContent":["\n@font-face {\n    font-family: 'Roboto';\n    src: url('Roboto-Bold.eot');\n    src: url('Roboto-Bold.eot?#iefix') format('embedded-opentype'),\n        url('Roboto-Bold.woff') format('woff'),\n        url('Roboto-Bold.ttf') format('truetype');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n}\n\n\n@font-face {\n    font-family: 'Roboto';\n    src: url('Roboto-Light.eot');\n    src: url('Roboto-Light.eot?#iefix') format('embedded-opentype'),\n        url('Roboto-Light.woff2') format('woff2'),\n        url('Roboto-Light.woff') format('woff'),\n        url('Roboto-Light.ttf') format('truetype');\n    font-weight: 300;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Roboto';\n    src: url('Roboto-Medium.eot');\n    src: url('Roboto-Medium.eot?#iefix') format('embedded-opentype'),\n        url('Roboto-Medium.woff') format('woff'),\n        url('Roboto-Medium.ttf') format('truetype');\n    font-weight: 500;\n    font-style: normal;\n    font-display: swap;\n}\n\n\n\n@font-face {\n    font-family: 'Roboto';\n    src: url('Roboto-Regular.eot');\n    src: url('Roboto-Regular.eot?#iefix') format('embedded-opentype'),\n        url('Roboto-Regular.woff2') format('woff2'),\n        url('Roboto-Regular.woff') format('woff'),\n        url('Roboto-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
