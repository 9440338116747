
import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  user:localStorage.getItem("user") || {},
  token:localStorage.getItem("token") || ""
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.user = action?.payload?.user;
      state.token = action?.payload?.token;
    },
    logoutUser: (state, action) => {
      state.user ="";
      state.token = "";
    }
  },
});

export const { loginUser,logoutUser } = userSlice.actions;

export default userSlice.reducer;

