import React from "react";

const Successfully = (props) => (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <circle cx="20" cy="20" r="20" fill="#16A070"></circle>
    <path
     fill="currentColor"
      d="M29.531 14.281l-12 12a.747.747 0 01-1.061 0l-5.25-5.25a.75.75 0 011.061-1.061l4.72 4.72L28.47 13.22a.75.75 0 011.061 1.061z"
    ></path>
  </svg>
);


export default Successfully;
