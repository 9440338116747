import React from "react";
const whatsaap = (props) =>  (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    fill="currentColor"
    viewBox="0 0 33 32"
    {...props}
  >
    <path
     fill="currentColor"
      d="M23.948 18.105l-4-2a1 1 0 00-1 .063l-1.836 1.224a5.07 5.07 0 01-2-2l1.225-1.836a1 1 0 00.063-1l-2-4A1 1 0 0013.5 8a5 5 0 00-5 5 11.012 11.012 0 0011 11 5 5 0 005-5 1 1 0 00-.552-.895zM19.501 22a9.01 9.01 0 01-9-9 3 3 0 012.411-2.943l1.435 2.875-1.221 1.818a1 1 0 00-.091.939 7.06 7.06 0 003.768 3.768 1 1 0 00.948-.082l1.826-1.218 2.875 1.436A3 3 0 0119.501 22zm-3-19A13 13 0 005.023 22.11l-1.418 4.256a2 2 0 002.53 2.53l4.256-1.419A13 13 0 1016.5 3zm0 24a10.984 10.984 0 01-5.508-1.476 1 1 0 00-.817-.084L5.5 27l1.559-4.675a1 1 0 00-.083-.817A11 11 0 1116.501 27z"
    ></path>
  </svg>
  );


export default whatsaap;
