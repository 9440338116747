import React from "react";

const Cart = (props) => (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="#fff"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
 fill="#fff"
        d="M26.767 5.359A1 1 0 0026 5H5.835l-.611-3.357A2 2 0 003.256 0H1a1 1 0 000 2h2.25l3.195 17.536a3 3 0 00.666 1.409A3.5 3.5 0 1012.661 22h5.678a3.5 3.5 0 103.161-2H9.396a1 1 0 01-.984-.821L8.016 17h14.5a3 3 0 002.952-2.464l1.52-8.357a1 1 0 00-.22-.82zM11 23.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm12 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm.5-9.321a1 1 0 01-.988.821H7.652L6.2 7H24.8L23.5 14.179z"
      ></path>
    </svg>
  );

export default Cart;
