import React from "react";
const SearchIcon = (props) =>  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
      {...props}
    >
      <path
        fill="#72727A"
        d="M26.708 25.293l-6.259-6.258a11.014 11.014 0 10-1.414 1.414l6.258 6.259a1 1 0 001.415-1.415zM3 12a9 9 0 119 9 9.01 9.01 0 01-9-9z"
      ></path>
    </svg>
  );


export default SearchIcon;
