import React from "react";

const Location = (props) =>  (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="28"
      fill="none"
      viewBox="0 0 22 28"
      {...props}
    >
      <path
         fill="currentColor"
        d="M11 6a5 5 0 100 10 5 5 0 000-10zm0 8a3 3 0 110-6 3 3 0 010 6zm0-14A11.013 11.013 0 000 11c0 3.925 1.814 8.085 5.25 12.031a31.774 31.774 0 005.181 4.788 1 1 0 001.148 0 31.775 31.775 0 005.171-4.788C20.181 19.085 22 14.925 22 11A11.012 11.012 0 0011 0zm0 25.75C8.934 24.125 2 18.156 2 11a9 9 0 1118 0c0 7.154-6.934 13.125-9 14.75z"
      ></path>
    </svg>
  );


export default Location;
