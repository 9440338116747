import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import catalogSlice from './slices/catalogSlice';
import mapSlice from './slices/mapSlice';
import addressSlice from "./slices/addressSlice"
import sellers from './slices/sellerSlice';
import ondcPreorder from './slices/ondcPreorderSlice';
import ondcPostorder from "./slices/ondcPostorderSlice";
import userSlice from "./slices/userSlice";

const rootReducer = combineReducers({
    user:userSlice,
    catalog: catalogSlice,
    mapApi: mapSlice,
    address: addressSlice,
    sellers: sellers,
    ondcPreorder:ondcPreorder,
    ondcPostorder:ondcPostorder
})

// export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
})

// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;