import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./styles.scss";
import crossIcon from "../../images/cross-icon.svg";
// import Loader from "../../components/common/loader";

const CancelOrder = (props) => {
  // const orderData = props?.orderData;
  // const [loading, setLoading] = useState(false);


  return (
    <Modal
      isOpen={props?.trackModal}
      size="md"
      centered
      toggle={() => props.setTrackModal(false)}
      className="RaiseTicketModal"
    >
      <ModalHeader>
        Track Order
        <button
          className="btn"
          onClick={() => {
            props.setTrackModal(false);
          }}
        >
          <img alt="Close" src={crossIcon} />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="RaiseTicketCards">
            Track  Order

        </div>
      </ModalBody>
    </Modal>
  );
};
export default CancelOrder;