import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const StoretimingModal = ({ isOpen, toggle, storeTimings }) => {

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const formatTime = (time) => {
    if (!time) return "N/A";
    return dayjs(time, "HHmm").format("hh:mm A");
  };

  const storeTiming = storeTimings && storeTimings.locations && storeTimings.locations[0] && storeTimings.locations[0].time && storeTimings.locations[0].time.range && storeTimings.locations[0].time.days
    ? daysOfWeek.map((day, index) => {
      const dayIndex = (index + 1).toString();
      const isOpen = storeTimings.locations[0].time.days.split(',').includes(dayIndex);
      return {
        day,
        openTime: isOpen ? formatTime(storeTimings.locations[0].time.range.start) : "Closed",
        closeTime: isOpen ? formatTime(storeTimings.locations[0].time.range.end) : ""
      };
    })
    : [];

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      centered
      toggle={toggle}
      className="StoreTimingModal"
    >
      <ModalHeader toggle={toggle}>
        Store Timings
      </ModalHeader>
      <ModalBody>
        <div className="StoreTimingCards">
          {storeTiming.length > 0 ? (
            <ul>
              {storeTiming.map((timing, index) => (
                <li key={index}>
                  <span>{timing.day}</span><span>{timing.openTime} {timing.closeTime && `- ${timing.closeTime}`}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No store timings available.</p>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StoretimingModal;
