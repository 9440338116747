import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "reactstrap";
import "./styles.scss";
import crossIcon from "../../images/cross-icon.svg";
import { useDispatch } from "react-redux";
import { fetchSellers } from "../../slices/sellerSlice";
const Address = (props) => {
    const dispatch = useDispatch();
    const [pincode, setPincode] = useState('');
    const [error, setError] = useState("");
    const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"))
    const [loading, setLoading] = useState(false);


    const validatePincode = async (pin) => {
        const pincodeRegex = /^\d{6}$/; // Regex to match exactly 6 digits
        if (!pin) {
            return "Please enter a pincode";
        } else if (!pincodeRegex.test(pin)) {
            return "Please enter a valid 6-digit pincode";
        }
        return "";
    };

    const handlePincodeChange = (e) => {
        const inputPin = e.target.value;
        setPincode(inputPin);

    };

    const findCoordinates = async () => {
        const errorMessage = await validatePincode(pincode);
        setError(errorMessage);
        if (errorMessage.length !== 0) {
            return
        }
        setLoading(true)
        const apiUrl = `https://nominatim.openstreetmap.org/search?postalcode=${pincode}&format=json`;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    const { lat, lon } = data[0];
                    const defaultAddress = {
                        latitude: lat,
                        longitude: lon,
                        zipcode: pincode
                      }
                      setLoading(false)
                 localStorage.setItem('defaultAddress', JSON.stringify(defaultAddress));
                    dispatch(
                        fetchSellers({
                            latitude: lat,
                            longitude: lon,
                            areaCode: pincode,
                        }))
                    props.setShowPreLoginAddessModal(false);

                } else {
                    setError("No coordinates found for the provided pincode.")
                }
            })
            .catch((error) => {
                setError("Error fetching coordinates")
                console.error('Error fetching coordinates:', error);
            });
    };


    return (

        <Modal
            isOpen={props.showPreLoginAddessModal}
            aria-labelledby="contained-modal-title-vcenter"
            contentLabel="Address Modal"
            centered
        // toggle={() => {
        //     alert('Please Specify Your Pincode');
        //     // props.setShowPreLoginAddessModal(false);
        // }}
        >
            <header className="ModalHeader">
                <h4> Please Add Address</h4>
                {
                defaultAddress &&
                    <img
                        alt="Close"
                        src={crossIcon}
                        className="login-cross"
                        onClick={() => {

                            props.setShowPreLoginAddessModal(false);

                        }}
                    />

               }
            </header>

            <div className="addressmodalBlock">
                <h2>Enter Your Pincode</h2>
                <div className="searchBlock">
                    {/* <img src={searchIcon} alt="Search1 Icon" /> */}
                    <input
                        type="text"
                        placeholder="Enter Pincode"
                        value={pincode}
                        onChange={handlePincodeChange}
                        maxLength={6}
                    />
                </div>
                {error.length > 0 &&
                    <p className="error">{error}</p>
                }

                <button
                    className="btn btn-primary w-100"
                    // style={{ width: "268px" }}
                    type="button"
                    onClick={findCoordinates}
                    disabled={loading}

                >
                 {loading?"Please Wait":"Submit"}   
                </button>

            </div>
        </Modal>


    );
};
export default Address;
