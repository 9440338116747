import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../images/searchIcon.svg";
import ShoppingCart from "../../components/svgicons/ShoppingCart";
import { ToastContainer } from "react-toastify";
import { Modal, ModalFooter, Offcanvas } from "reactstrap";
import LogoutLogo from "../../images/logout-logo.png";
import BrandLogo from "../../images/kikoimg.svg";
import ManageAddress from "../ManageAddress/component";
import { fetchCatelogues ,updateCartItem } from "../../slices/catalogSlice";
import HomeHeaderIcon from "../../images/ProductImg/home-header-icon.svg"
import CartHeader from "../../components/common/CartHeader";
import Login from "../Login/component";
import PreloginAddress from "../ManageAddress/preloginaddress";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../slices/userSlice";
import "./styles.scss";
import Order from "../Order/component";
import { ProfileUser } from "../../components/svgicons";
import { debounce } from "lodash";
import pin from "../../images/pin.svg"


const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addressModal, setAddressModal] = useState(false);
  const [logout, setlogout] = useState(false);
  const [showPreLoginAddessModal, setShowPreLoginAddessModal] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const addressData = JSON.parse(localStorage.getItem("addressData"));
  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const isCheckoutPage = window.location.href.includes("buyer-checkout");
  const [orderHistory,setOrderhistory] = useState(false)
  const [yourAddress,setYouraddress]=useState(false)
  const token = useSelector((s) => s.user.token);
  const ondcSelectstatus = useSelector((s) => s.ondcPreorder.ondcSelectstatus);
  
  const userLogout = () => {
    dispatch(updateCartItem([]))
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("cart");
    localStorage.removeItem("sellerDetails");
    localStorage.removeItem("slug");
    if (defaultAddress?.address) {
      delete defaultAddress?.address;
      localStorage.setItem("defaultAddress", JSON.stringify(defaultAddress));
    }
    dispatch(logoutUser())
    navigate("/")
    setlogout(false)
    // window.location.reload();
  };


  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  // const handleButtonClick = () => {
  //   if (token) {
  //     toggleDropdown();
  //     setIsDropdownOpen(!isDropdownOpen);
  //   } else {
  //     handleLoginClick();
  //   }
  // };


  // const getZipcodeFromAddress = () => {
  //   if (defaultAddress) {
  //     setZipcode(defaultAddress.zipcode);
  //   }
  // };
  const handleLoginClick = () => {
    if (!token) {
      setModalIsOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
    console.log(!isCartOpen, "Toggling cart state...");
  };
  const closeCart = () => {
    setIsCartOpen(false);
  };
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("user"));
    if (userId) {
      // dispatch(fetchAddress({ userId }));
    }
  }, [dispatch]);
  useEffect(() => {
    const getZipcodeFromAddress = () => {
      if (defaultAddress) {
        setZipcode(defaultAddress.zipcode);
        setCity(defaultAddress.address?.city);
      }
    };
    const addressData = JSON.parse(localStorage.getItem("addressData"));
    if (addressData && addressData?.zipcode) {
      setZipcode(addressData?.zipcode);
      setCity(addressData?.address.city)
    } else {
      getZipcodeFromAddress();
    }
  }, [addressData?.zipcode, defaultAddress]);

  useEffect(() => {
    if (!defaultAddress && !token) {
      setShowPreLoginAddessModal(true);
    }
  }, [defaultAddress, token]);

  const user = JSON.parse(localStorage.getItem("user"));

  // const getUserFromLocalStorage = () => {
  //   try {
  //     return JSON.parse(localStorage.getItem("user") || "");
  //   } catch (error) {
  //     return null;
  //   }
  // };

  const getSellerDetails = () => {
    try {
      return JSON.parse(localStorage.getItem("sellerDetails") || "");
    } catch (error) {
      return null;
    }
  };

  const sellerDetails = getSellerDetails();

  // const userData = getUserFromLocalStorage();

  const [catalogueData, setCatalogData] = useState([]);

  // let cartItemsLength = props.cartItemLength;
  let cartItems = JSON.parse(localStorage.getItem("cart"));

  let cartItemsLength = cartItems?.length || 0;

  const onChange = debounce((searchText) => {
    console.log("HEADER : SEARCH DATA", searchText);
    dispatch(fetchCatelogues({
      search: searchText,
      pincode: defaultAddress?.zipcode
      // sellerId: sellerDetails?._id,
    }));
  }, 300);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <header className="headerSection">
        <ToastContainer
          autoClose={300}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="right"
          position="top-right"
        />
        <div>
          <div className="headermain">
            <div className="header-left">
              <div className="logo-address-flex">
                {/* <div className="contactDetail">
              <p
                className="m-0"
                onClick={() => {
                  navigate(localStorage.getItem("slug"));
                }}
              >
                <img
                  className="homeicon"
                  src={HomeIcon}
                  style={{ maxWidth: "20px" }}
                />
              </p>
              <p
                className="contactNo"
                onClick={() => {
                  copyToClipboard(sellerDetails?.phone);
                }}
              >
                {sellerDetails?.phone}
              </p>

              {sellerDetails?.whatsAppNumber && (
                <p
                  className="WhatsaapNo"
                  onClick={() => {
                    copyToClipboard(sellerDetails?.whatsAppNumber);
                  }}
                >
                  {"+91" + sellerDetails?.whatsAppNumber}
                </p>
              )}
            </div> */}
                <a href="/" className="brand-logo">
                  <img src={BrandLogo} alt="Logo" className="logo-icon" />
                  <img src={HomeHeaderIcon} alt="logo" className="home-icon" />
                </a>
                <p
                  className="buyer-address"
                  onClick={() => {
                    if (!token) {
                      // setModalIsOpen(true);
                      setShowPreLoginAddessModal(true)
                    } else {
                      setAddressModal(true);
                    }
                  }}
                >
                  <img src={pin} alt="location" />
                  {zipcode && zipcode.length !== 0 && (
                    <div>
                      <div className="deliver-to">Deliver to </div>
                      <p className=" buyer-address-pincode">{city} {zipcode}</p>
                    </div>
                  )}
                </p>
              </div>
            </div>
            <div className="header-center">
              <div>
                <div className="search-top">
                  <div className="searchBar">
                    <div className="searchInput">
                      <button>
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <input
                        type="text"
                        autoFocus={window.location.pathname === "/search"}
                        placeholder="Search for a product"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (window.location.pathname !== "/search") {
                            navigate("/search");
                          }
                        }}
                      />
                    </div>
                    {catalogueData && catalogueData?.length > 0 ? (
                      <div className="searchDropdown">
                        {catalogueData?.map((data) => {
                          return (
                            <p
                              onClick={() => {
                                !props.func &&
                                  navigate("/productDetails", {
                                    state: {
                                      catalogData: data,
                                      catalogDataArray: {},
                                    },
                                  });
                                props.func && props.func(data);
                                props.func && setCatalogData([]);
                              }}
                            >
                              {data.productName}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="header-right">
              <div className="headerRight">
                {/* <div className="popover__wrapper">
                <h2 className="popover__title m-0">
                  {token && token !== "" ? (
                    <div className="profileImg">
                      <Profile style={{ maxWidth: "28px" }} />
                      {userData?.name ? userData?.name : "Hello User"}
                    </div>
                  ) : (
                    <div className="profileDetails">
                      <a href="/login">
                        <Profile className="Icon" />
                        Sign In
                      </a>
                    </div>
                  )}
                </h2>
                {token && token !== "" && (
                  <div className="popover__content">
                    <ul className="profile-editor">
                      <li>
                        <a href="/buyerprofile">
                          <img
                            src={BlueProfile}
                            className="me-2"
                            alt="BlueProfile"
                          />
                          Your Account
                        </a>
                      </li>
                      <li>
                        <a href="/order">
                          <img
                            src={BlueOrder}
                            className="me-2"
                            alt="BlueOrder"
                          />
                          Your Orders
                        </a>
                      </li>
                      <li>
                        <a href="/manageAddress">
                          <img
                            src={BlueAddress}
                            className="me-2"
                            alt="BlueAddress"
                          />
                          Your Addresses
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          setlogout(true);
                        }}
                        style={{ fontSize: "14px" }}
                      >
                        <img
                          src={BlueSignOut}
                          className="me-2"
                          alt="BlueSignOut"
                        />
                        Logout
                      </li>
                    </ul>
                  </div>
                )}
              </div> */}
                {/* <Popover content={token && token !== "" ? content : null} trigger="hover" overlayInnerStyle={{ width: "160px", marginRight: "20px" }} >
                {token && token !== "" ? <div className="profileImg"><Profile style={{ maxWidth: "28px" }} />{userData?.name ? userData?.name : "Hello User"}</div> : <div className="profileDetails"><a href="/login">
                  <Profile className="Icon" />Sign In
                </a></div>
                }
              </Popover> */}
                <div className="loginHeading">
                  {/* <p>Login</p> */}
                  <div className="account-dropdown  account-dropdown-show" ref={dropdownRef}>
                    <button
                      className={token ? "btn " : "btn"}
                      type="button"
                      // id="dropdownMenuButton1"
                      // data-bs-toggle={token ? "dropdown" : "btn"}
                      // aria-expanded="false"
                      onClick={() => {
                        if (token) {
                          toggleDropdown();
                        } else {
                          handleLoginClick();
                        }
                      }}

                    >
                      {token ? <ProfileUser /> : "Login"}
                    </button>
                    {isDropdownOpen &&
                      <ul
                        className="account-menu"
                      // aria-labelledby="dropdownMenuButton1"
                      >
                        <li className="account-dropdown__account-info">
                          <div className="account-info--heading">My Account</div>
                          <div className="account-number">{user?.phone}</div>
                        </li>
                        <li>
                          {/* <a className="dropdown-item"> */}
                          <div
                            className="menu-logout-list"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasAddress"
                            aria-controls="offcanvasRightaddress"
                            onClick={() => {
                              setIsDropdownOpen(false);
                              setYouraddress(true)
                            }}
                          >
                            Your Addresss
                          </div>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a className="dropdown-item"> */}
                          <div
                            className="menu-logout-list"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasOrder"
                            aria-controls="offcanvasRightOrder"
                            onClick={() => {
                              setIsDropdownOpen(false);
                              setOrderhistory(true)
                            }}
                          >
                            Order History
                          </div>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a
                        className="dropdown-item"
                        // href="#"
                        data-bs-dismiss="offcanvas"
                      > */}
                          <div
                            type="button"
                            data-bs-dismiss="offcanvas"
                            className="menu-logout-list"
                            onClick={() => {
                              setlogout(true);
                              setIsDropdownOpen(false);
                            }}
                          >
                            Logout
                          </div>
                          {/* </a> */}
                        </li>
                      </ul>}
                  </div>
                </div>

                {!isCheckoutPage && (

                  <div>

                    <button
                      className="btn left--icon p-0"
                      type="button"
                      // data-bs-toggle="offcanvas"
                      // data-bs-target="#offcanvasRight"
                      // aria-controls="offcanvasRight"
                      onClick={() => {
                        toggleCart();
                      }}
                    >
                      <div className="profileDetails">
                        <ShoppingCart className="Icon" />
                        {cartItemsLength && cartItemsLength > 0 ? (
                          <div className="cartCounter">{cartItemsLength}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </button>

                  </div>
                )}
              </div>
            </div>
            <Modal
              isOpen={addressModal}
              size="lg"
              toggle={() => setAddressModal(false)}
            >
              <div className="addressmodalBlock">
                <ManageAddress
                  setCity={setCity}
                  setZipcode={setZipcode}
                  setAddressModal={setAddressModal}
                />
              </div>
            </Modal>
          </div>
          {modalIsOpen && (
            <Login
              isOpenFromCart={false}
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
            ></Login>
          )}
          {showPreLoginAddessModal && (
            <PreloginAddress
              showPreLoginAddessModal={showPreLoginAddessModal}
              setShowPreLoginAddessModal={setShowPreLoginAddessModal}
            ></PreloginAddress>
          )}
        </div>
      </header>
      <div className="Mobile-header">
        <div className="gap-3 w-100 searchCenter">
          <div className="search-top">
            <div className="searchBar">
              <div className="searchInput">
                <button>
                  <img src={searchIcon} alt="Search Icon" />
                </button>
                <input
                  type="text"
                  autoFocus={window.location.pathname === "/search"}
                  placeholder="Search for a product"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.location.pathname !== "/search") {
                      navigate("/search");
                    }
                  }}
                />
              </div>
              {catalogueData && catalogueData?.length > 0 ? (
                <div className="searchDropdown">
                  {catalogueData?.map((data) => {
                    return (
                      <p
                        onClick={() => {
                          !props.func &&
                            navigate("/productDetails", {
                              state: {
                                catalogData: data,
                                catalogDataArray: {},
                              },
                            });
                          props.func && props.func(data);
                          props.func && setCatalogData([]);
                        }}
                      >
                        {data.productName}
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>

      </div>

      {/* <div className="mobileSearch">
        <div className="container">
          <div className="searchBar">
            <div className="searchInput">
              <input
                type="text"
                placeholder="Search for a product"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
              <button>
                <img src={searchIcon} alt="Search Icon" />
              </button>
            </div>
            {catalogueData && catalogueData?.length > 0 ? (
              <div className="searchDropdown">
                {catalogueData?.map((data) => {
                  return (
                    <p
                      onClick={() => {
                        !props.func &&
                          navigate("/productDetails", {
                            state: { catalogData: data, catalogDataArray: {} },
                          });
                        props.func && props.func(data);
                        props.func && setCatalogData([]);
                      }}
                    >
                      {data.productName}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div> */}

      <Modal
        isOpen={logout}
        aria-labelledby="contained-modal-title-vcenter"
        toggle={() => {
          setlogout(false);
        }}
        centered
        className="clearcartmodal"
      >
        <div className="confirmLogout">
          <img src={LogoutLogo} alt="Logout" />
          <h1 className="confirm-subtitle">
            Thank you for visiting {sellerDetails?.storeName}
          </h1>
        </div>
        <ModalFooter className="border-0 flex-column">
          <h5 className="confirmtitle">Are you sure you want to logout?</h5>
          <div className="d-flex justify-content-center gap-2">
            <button
              onClick={() => {
                setlogout(false);
              }}
            >
              No
            </button>
            <button
              onClick={() => {
                userLogout();
              }}
            >
              Yes
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Offcanvas isOpen={isCartOpen} toggle={closeCart} direction="end" backdrop={ondcSelectstatus === "loading" ? "static" : true}  className="offcanvas offcanvas-end sidebaroffcanvas">
            <CartHeader
              isCartOpen={isCartOpen}
              closeCart={closeCart}
            />
      </Offcanvas>
      
      {/* <div
        className="offcanvas offcanvas-end sidebaroffcanvas"
        tabIndex="-1"
        id="offcanvasRight"
        sidebaroffcanvas
        aria-labelledby="offcanvasRightLabel"
        // data-bs-backdrop={isCartOpen?"false":"true"}
        // data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <p
            className="m-0"
            style={{ fontSize: "16px", color: "#000", fontWeight: "500" }}
          >
            My Cart
          </p>

          <button
            type="button"
            disabled={disableCrossButton}
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={closeCart}
          ></button>
        </div>
        {<CartHeader
        isCartOpen={isCartOpen}
        setDisableCrossButton={setDisableCrossButton}
        disableCrossButton={disableCrossButton}
        />}
      </div> */}
      <div
        className="offcanvas offcanvas-end orderSidebar "
        tabIndex="-1"
        orderSidebar
        id="offcanvasOrder"
        aria-labelledby="offcanvasRightLabelOrder"
      >
        <div className="offcanvas-header">
          <p className="mb-0 profile-info-title">Order</p>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {orderHistory && token &&
            <Order />
          }
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end orderSidebar "
        // tabindex="-1"
        orderSidebar
        id="offcanvasAddress"
        aria-labelledby="offcanvasRightLabelAddress"
      >
        <div className="offcanvas-header">
          {/* <p className="mb-0 profile-info-title">Your Address</p> */}

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body" style={{ background: "#fff" }}>
          {yourAddress && token &&
            <ManageAddress setZipcode={setZipcode}
              setAddressModal={setAddressModal}
              isInHeader={true} />
          }
        </div>
      </div>
    </>
  );
};
export default Header;
