import React from "react";

const CrossIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      fill="none"
      viewBox="0 0 17 18"
    >
      <path
        fill="currentColor"
        d="M16.735 15.74a.83.83 0 01-.585 1.414.828.828 0 01-.585-.243l-6.86-6.86-6.859 6.86a.828.828 0 11-1.17-1.17l6.86-6.86-6.86-6.859a.828.828 0 011.17-1.17l6.86 6.86 6.859-6.86a.828.828 0 011.17 1.17l-6.86 6.86 6.86 6.859z"
      ></path>
    </svg>
);

export default CrossIcon;
