import React from "react";

const Phone = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 -12 32 32"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" transform="translate(-414 -1049)">
        <path d="M442 1049h-24a4 4 0 100 8h24a4 4 0 100-8"></path>
      </g>
    </g>
  </svg>
);

export default Phone;
